/* eslint-disable no-debugger */
import Vue from "vue";
import LanguagesManager from "@/services/LanguagesManager";
// import { UserService } from "@/services/index";

const mockedStore = {
  commit: () => {},
};

export default class ApiClient {
  constructor() {}

  getStore() {
    // IPC(*): Precisa disso pois certas páginas carregam o ApiClient antes de instanciar o Vue
    return window.__zooxStore ? window.__zooxStore : mockedStore;
  }

  setLangInterceptor() {
    return (request, next) => {
      const currentLang = LanguagesManager.getCurrentLanguage() || "en";
      request.headers.set("lang", currentLang);
      next();
    };
  }

  setHeaders(removeContentType = false, showLoading = true) {
    const headersConfig = {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
      "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
      showLoading: showLoading ? "1" : "0", // tem q ser string
    };

    const groupOrCompany = localStorage.getItem("groupOrCompany");
    if (groupOrCompany) {
      headersConfig["x-group-or-company"] = groupOrCompany;
    }

    if (removeContentType) {
      delete headersConfig["Content-Type"];
    }
    return headersConfig;
  }

  callWS(method, url, data, showException = true, showLoading = true) {
    return new Promise((resolve, reject) => {
      let headers = this.setHeaders(false, showLoading);

      const event = Object.assign({}, data);
      event.showLoading = showLoading;
      event.requestId = new Date().getTime();

      if (Vue.$globalEvent) {
        Vue.$globalEvent.$emit("httpRequestStart", event);
      }

      const store = this.getStore();
      store.commit("app/START_LOADING", event);
      /*const userService = new UserService();
      const currentUserLogged = userService.getUser();
      if (currentUserLogged) {
        const currentUserLoggedToken = currentUserLogged.token;
        if (currentUserLoggedToken) {
          headers.Authorization = `Bearer ${currentUserLoggedToken}`;
        }
      }*/
      // Vue.http.interceptors.push(this.setLangInterceptor());

      let baseUrl = process.env.VUE_APP_API_BASE_URL;

      const currentUserLoggedToken = localStorage.getItem("zooxeye-token");
      if (currentUserLoggedToken) {
        headers.Authorization = `Bearer ${currentUserLoggedToken}`;
      }

      if (url.indexOf("http") > -1 || url.indexOf("https") > -1) {
        const u = new URL(url);
        baseUrl = u.origin;
        url = u.pathname.replace("/", "") + u.search;
      }

      Vue.http({
        method: method,
        url: `${baseUrl}/${url}`,
        body: data,
        headers: headers,
      })
        .then((resp) => {
          if (Vue.$globalEvent) {
            Vue.$globalEvent.$emit("httpRequestEnd", resp.body || resp);
          }

          event.response = resp;
          store.commit("app/FINISH_LOADING", event);

          resolve(resp.body || resp);
        })
        .catch((err) => {
          if (err.status === 401)
            window.location.href = process.env.VUE_APP_ZOOX_EYE_URL;

          this.handleError(err, method, showException);
          if (Vue.$globalEvent) {
            Vue.$globalEvent.$emit("httpRequestEnd", err);
          }

          event.response = err;
          store.commit("app/FINISH_LOADING", event);

          return reject(err);
        });
    });
  }

  get(url, query = null, showException = true, showLoading = true) {
    if (query) {
      url = url + "?" + this.serialize(query);
    }

    return this.callWS("GET", url, null, showException, showLoading);
  }

  post(url, data, showException = true, showLoading = true) {
    return this.callWS("POST", url, data, showException, showLoading);
  }

  put(url, data, showException = true, showLoading = true) {
    return this.callWS("PUT", url, data, showException, showLoading);
  }

  delete(url, showException = true, showLoading = true) {
    return this.callWS("DELETE", url, {}, showException, showLoading);
  }

  serialize(obj) {
    var str = [];
    for (var p in obj)
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    return str.join("&");
  }

  handleError(error, operation = "operation", showException = true) {
    if (error) {
      error.operation = operation;
      error.showException = showException;
      if (Vue.$globalEvent) {
        Vue.$globalEvent.$emit("httpError", error);
      }
    }
  }

  downLoadFile(method, url, data, showException = true) {
    let baseUrl = process.env.VUE_APP_API_BASE_URL;
    if (url.indexOf("http") > -1 || url.indexOf("https") > -1) {
      const u = new URL(url);
      baseUrl = u.origin;
      url = u.pathname.replace("/", "") + u.search;
    }

    let headers = {};
    /*const userService = new UserService();
    const currentUserLogged = userService.getUser();
    
    if (currentUserLogged) {
      const currentUserLoggedToken = currentUserLogged.token;
      if (currentUserLoggedToken) {
        headers.Authorization = `Bearer ${currentUserLoggedToken}`;
      }
    }*/

    const event = {};
    event.showLoading = true;
    event.requestId = new Date().getTime();
    const store = this.getStore();
    store.commit("app/START_LOADING", event);
    return new Promise((resolve, reject) => {
      Vue.http({
        method: method,
        url: `${baseUrl}/${url}`,
        body: data,
        headers: headers,
        responseType: "blob",
      })
        .then((response) => {
          if (response.body) {
            this.makeDownLoadFile(response.body, data.file_name);
          }
          store.commit("app/FINISH_LOADING", event);
          return resolve(response.body);
        })
        .catch((err) => {
          this.handleError(err, method, showException);
          if (Vue.$globalEvent) {
            Vue.$globalEvent.$emit("httpRequestEnd", err);
          }

          event.response = err;
          store.commit("app/FINISH_LOADING", event);

          return reject(err);
        });
    });
  }

  /**
   * Methodo usado para fazer download do Arquivo
   * @param data - Blob data
   */
  makeDownLoadFile(data, fileName) {
    const a = document.createElement("a");
    document.body.appendChild(a);

    const blob = new Blob([data]);
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = fileName;
    a.click();

    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }, 0);
  }
}
