export const setUser = async ({ commit }, val) => {
  commit("setUser", val);
};
export const setCompany = ({ commit }, val) => {
  commit("setCompany", val);
};
export const reset = async ({ commit }) => {
  commit("reset");
};
export const setCities = async ({ commit }, val) => {
  commit("setCities", val);
};
export const setNeighbourhoods = async ({ commit }, val) => {
  commit("setNeighbourhoods", val);
};
export const setStates = async ({ commit }, val) => {
  commit("setStates", val);
};
export const setActualLanguage = async ({ commit }, val) => {
  commit("SET_LANGUAGE", val);
};

export const setDrawer = ({ commit }, value) => {
  commit("SET_DRAWER", value);
};

export const setSnack = async ({ commit, state }, snack) => {
  commit("setSnack", {
    ...state.snack,
    msg: snack.msg,
    color: snack.color || "info",
    show: true,
  });
};

export const hideSnack = async ({ commit }) => {
  commit("hideSnack");
};

export const START_LOADING = ({ commit }, loading) => {
  commit("START_LOADING", loading);
};

export const FINISH_LOADING = ({ commit }, loading) => {
  commit("FINISH_LOADING", loading);
};

export const SET_LOADING = ({ commit }, loading) => {
  commit("SET_LOADING", loading);
};

export const SET_CURRENT_AUDIENCE_INFO = ({ commit }, info) => {
  commit("SET_CURRENT_AUDIENCE_INFO", info);
};

export const RESET_CURRENT_AUDIENCE_INFO = ({ commit }) => {
  commit("RESET_CURRENT_AUDIENCE_INFO");
};

export const CREATE_BASIC_JOURNEY = ({commit}, payload) =>{
  commit('CREATE_BASIC_JOURNEY', payload)
}

export const RESET_BASIC_JOURNEY = ({commit}) =>{
  commit('RESET_BASIC_JOURNEY')
}

export const CREATE_FILTER_CHIP = ({ commit }, payload) => {
  // const NEW_CHIP = {
  //   id: payload.id,
  //   label: payload.label,
  //   title: payload.title,
  //   type: payload.type,
  // };

  commit("INSERT_CHIP_ON_LIST", payload);
};

export const REMOVE_FILTER_CHIP_ITEM = ({ commit }, payload) => {
  commit("REMOVE_FILTER_CHIP_ITEM", payload);
};

export const SET_CHIP_TO_EDIT = ({ commit }, payload) => {
  commit("SET_CHIP_TO_EDIT", payload);
};

export const CREATE_DEFAULT_CHIP = ({commit}, payload) =>{
  commit('CREATE_DEFAULT_CHIP', payload)
}

export const UPDATE_CHIP = ({ commit }, payload) => {
  commit("UPDATE_CHIP", payload);
};

export const RESET_CHIP_TO_EDIT = ({ commit }) => {
  commit("RESET_CHIP_TO_EDIT");
};

export const REMOVE_CHILD_CHIP_ON_FATHER = ({commit}, payload) =>{
  commit('REMOVE_CHILD_CHIP_ON_FATHER', payload)
}

export const RESET_FILTER_CHIP_LIST = ({ commit }) =>
  commit("RESET_FILTER_CHIP_LIST");
