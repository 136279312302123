import { chain } from "lodash";
// import { UserService } from "@/services";
// const userService = new UserService()

/*export const appUser = (state) => {
  const user = userService.getUser();
  if (state.appUser) {
    return state.appUser;
  } else if (user) {
    state.appUser = user
    return state.appUser;
  }
  return {};
};*/

export const $defaultNodeToCreate = (state) => state.defaultNodeToCreate;

export const $currentEditChip = (state) => state.currentEditChip;

export const $currentAudienceInfo = (state) => {
  return state.currentAudienceInfo;
};

export const $chipsFilterList = (state) => state.chipsFilterList;

export const currentCompany = (state) => {
  return state.currentCompany;
};
export const isLoading = (state) => {
  if (state.loading) {
    return state.loading > 0;
  }
  return false;
};

export const hasPermission = (state) => (name) => {
  return state.permission.includes(name);
};

export const sidebarMenuOptions =
  (state) =>
  (route, permissions = [], context) => {
    const menuOptions = chain(state.menuOptions)
      .filter((m) => {
        if (context && m.context) {
          return m.context.includes(context);
        }
        return true;
      })
      .map((menuOption) => ({
        ...menuOption,
        isActive: menuOption.hasRoute(route),
      }))
      .filter((option) => {
        option.options = option.options.filter((e) => {
          return (
            !e.permission ||
            (permissions.indexOf(e.permission) > -1 &&
              (!e.context || e.context.includes(context)))
          );
        });
        return option.options.length > 0 || option.route === "/";
      });

    return menuOptions.value();
  };
