import { getMenuOptions } from './menuOptions'

export const getDefaultState = () => {
  return {
    defaultNodeToCreate:{ isToCreate: false, node: null, mountObj: null},
    chipsFilterList:[],
    currentEditChip: null,
    appUser: null,
    currentAudienceInfo: null,
    currentCompany: null,
    menuOptions: getMenuOptions(),
    loading: 0,
    drawer: null,
    cities: [],
    neighbourhoods: [],
    states: [],
    globalLocale: null,
    languages: [{
        code: "en",
        locale: "en-US",
        title: "GLOBAL.LANG_ENGLISH",
        image: "um-round",
      },

      {
        code: "pt",
        locale: "pt-BR",
        title: "GLOBAL.LANG_PORTUGUESE",
        image: "br-round",
      },
    ],
    snack: {
      msg: "",
      color: "info", // ['success', 'info', 'error', 'cyan darken-2']",
      show: false,
    },
    snacks: [{
      msg: "",
      color: "info", // ['success', 'info', 'error', 'cyan darken-2']",
      show: false,
    }, ],
    permission: [],
    locationCoordinatesOfPlacesMap: {
      "type": "FeatureCollection",
      "features": [{
        "type": "Feature",
        "properties": { "lat_y": -22.901430130004883, "long_x": -43.1019401550293 },
        "geometry": { "type": "Point", "coordinates": [-43.1019401550293, -22.901430130004883] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -22.9105281829834, "long_x": -43.05215835571289 },
        "geometry": { "type": "Point", "coordinates": [-43.05215835571289, -22.9105281829834] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -22.949838638305664, "long_x": -43.33127975463867 },
        "geometry": { "type": "Point", "coordinates": [-43.33127975463867, -22.949838638305664] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -22.935590744018555, "long_x": -43.233089447021484 },
        "geometry": { "type": "Point", "coordinates": [-43.233089447021484, -22.935590744018555] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -23.529367446899414, "long_x": -46.82355880737305 },
        "geometry": { "type": "Point", "coordinates": [-46.82355880737305, -23.529367446899414] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -22.9160213470459, "long_x": -43.24544906616211 },
        "geometry": { "type": "Point", "coordinates": [-43.24544906616211, -22.9160213470459] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -16.5139102935791, "long_x": -50.37935256958008 },
        "geometry": { "type": "Point", "coordinates": [-50.37935256958008, -16.5139102935791] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -22.944345474243164, "long_x": -43.059024810791016 },
        "geometry": { "type": "Point", "coordinates": [-43.059024810791016, -22.944345474243164] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -22.947092056274414, "long_x": -43.19395065307617 },
        "geometry": { "type": "Point", "coordinates": [-43.19395065307617, -22.947092056274414] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -22.753286361694336, "long_x": -43.48405838012695 },
        "geometry": { "type": "Point", "coordinates": [-43.48405838012695, -22.753286361694336] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -23.52198600769043, "long_x": -46.8541145324707 },
        "geometry": { "type": "Point", "coordinates": [-46.8541145324707, -23.52198600769043] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -16.263628005981445, "long_x": -47.95858383178711 },
        "geometry": { "type": "Point", "coordinates": [-47.95858383178711, -16.263628005981445] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -22.93971061706543, "long_x": -43.3384895324707 },
        "geometry": { "type": "Point", "coordinates": [-43.3384895324707, -22.93971061706543] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -22.915678024291992, "long_x": -43.07207107543945 },
        "geometry": { "type": "Point", "coordinates": [-43.07207107543945, -22.915678024291992] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -22.851991653442383, "long_x": -43.26021194458008 },
        "geometry": { "type": "Point", "coordinates": [-43.26021194458008, -22.851991653442383] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -22.85628318786621, "long_x": -43.35256576538086 },
        "geometry": { "type": "Point", "coordinates": [-43.35256576538086, -22.85628318786621] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -22.788305282592773, "long_x": -43.29694747924805 },
        "geometry": { "type": "Point", "coordinates": [-43.29694747924805, -22.788305282592773] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -22.92426109313965, "long_x": -43.093013763427734 },
        "geometry": { "type": "Point", "coordinates": [-43.093013763427734, -22.92426109313965] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -22.945547103881836, "long_x": -43.054561614990234 },
        "geometry": { "type": "Point", "coordinates": [-43.054561614990234, -22.945547103881836] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -23.567476272583008, "long_x": -46.48710250854492 },
        "geometry": { "type": "Point", "coordinates": [-46.48710250854492, -23.567476272583008] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -22.430391311645508, "long_x": -42.979373931884766 },
        "geometry": { "type": "Point", "coordinates": [-42.979373931884766, -22.430391311645508] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -16.6512393951416, "long_x": -49.381656646728516 },
        "geometry": { "type": "Point", "coordinates": [-49.381656646728516, -16.6512393951416] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -16.63905143737793, "long_x": -49.32706832885742 },
        "geometry": { "type": "Point", "coordinates": [-49.32706832885742, -16.63905143737793] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -23.530054092407227, "long_x": -46.838321685791016 },
        "geometry": { "type": "Point", "coordinates": [-46.838321685791016, -23.530054092407227] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -16.743249893188477, "long_x": -48.51682662963867 },
        "geometry": { "type": "Point", "coordinates": [-48.51682662963867, -16.743249893188477] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -22.90520668029785, "long_x": -43.10468673706055 },
        "geometry": { "type": "Point", "coordinates": [-43.10468673706055, -22.90520668029785] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -22.904348373413086, "long_x": -43.060054779052734 },
        "geometry": { "type": "Point", "coordinates": [-43.060054779052734, -22.904348373413086] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -22.957048416137695, "long_x": -43.182621002197266 },
        "geometry": { "type": "Point", "coordinates": [-43.182621002197266, -22.957048416137695] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -22.8665828704834, "long_x": -43.33883285522461 },
        "geometry": { "type": "Point", "coordinates": [-43.33883285522461, -22.8665828704834] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -22.886152267456055, "long_x": -43.47135543823242 },
        "geometry": { "type": "Point", "coordinates": [-43.47135543823242, -22.886152267456055] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -16.678361892700195, "long_x": -49.22544479370117 },
        "geometry": { "type": "Point", "coordinates": [-49.22544479370117, -16.678361892700195] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -22.910184860229492, "long_x": -43.109493255615234 },
        "geometry": { "type": "Point", "coordinates": [-43.109493255615234, -22.910184860229492] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -22.74916648864746, "long_x": -43.451786041259766 },
        "geometry": { "type": "Point", "coordinates": [-43.451786041259766, -22.74916648864746] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -23.552026748657227, "long_x": -46.84793472290039 },
        "geometry": { "type": "Point", "coordinates": [-46.84793472290039, -23.552026748657227] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -21.375703811645508, "long_x": -41.34756088256836 },
        "geometry": { "type": "Point", "coordinates": [-41.34756088256836, -21.375703811645508] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -17.721548080444336, "long_x": -48.61055374145508 },
        "geometry": { "type": "Point", "coordinates": [-48.61055374145508, -17.721548080444336] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -22.838773727416992, "long_x": -43.31583023071289 },
        "geometry": { "type": "Point", "coordinates": [-43.31583023071289, -22.838773727416992] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -23.5231876373291, "long_x": -46.84587478637695 },
        "geometry": { "type": "Point", "coordinates": [-46.84587478637695, -23.5231876373291] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -22.937307357788086, "long_x": -43.028812408447266 },
        "geometry": { "type": "Point", "coordinates": [-43.028812408447266, -22.937307357788086] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -23.55717658996582, "long_x": -46.84415817260742 },
        "geometry": { "type": "Point", "coordinates": [-46.84415817260742, -23.55717658996582] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -22.931299209594727, "long_x": -43.071041107177734 },
        "geometry": { "type": "Point", "coordinates": [-43.071041107177734, -22.931299209594727] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -15.441713333129883, "long_x": -47.61457443237305 },
        "geometry": { "type": "Point", "coordinates": [-47.61457443237305, -15.441713333129883] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -22.906923294067383, "long_x": -43.102970123291016 },
        "geometry": { "type": "Point", "coordinates": [-43.102970123291016, -22.906923294067383] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -22.88203239440918, "long_x": -43.1239128112793 },
        "geometry": { "type": "Point", "coordinates": [-43.1239128112793, -22.88203239440918] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -22.86675453186035, "long_x": -43.45075607299805 },
        "geometry": { "type": "Point", "coordinates": [-43.45075607299805, -22.86675453186035] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -22.83808708190918, "long_x": -43.375911712646484 },
        "geometry": { "type": "Point", "coordinates": [-43.375911712646484, -22.83808708190918] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -21.758337020874023, "long_x": -41.334171295166016 },
        "geometry": { "type": "Point", "coordinates": [-41.334171295166016, -21.758337020874023] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -22.127408981323242, "long_x": -41.86872482299805 },
        "geometry": { "type": "Point", "coordinates": [-41.86872482299805, -22.127408981323242] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -23.516149520874023, "long_x": -46.83797836303711 },
        "geometry": { "type": "Point", "coordinates": [-46.83797836303711, -23.516149520874023] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -22.73594856262207, "long_x": -43.43427658081055 },
        "geometry": { "type": "Point", "coordinates": [-43.43427658081055, -22.73594856262207] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -22.89301872253418, "long_x": -43.114986419677734 },
        "geometry": { "type": "Point", "coordinates": [-43.114986419677734, -22.89301872253418] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -23.525075912475586, "long_x": -46.83694839477539 },
        "geometry": { "type": "Point", "coordinates": [-46.83694839477539, -23.525075912475586] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -23.532285690307617, "long_x": -46.84621810913086 },
        "geometry": { "type": "Point", "coordinates": [-46.84621810913086, -23.532285690307617] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -22.891130447387695, "long_x": -43.11635971069336 },
        "geometry": { "type": "Point", "coordinates": [-43.11635971069336, -22.891130447387695] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -22.95790672302246, "long_x": -43.19807052612305 },
        "geometry": { "type": "Point", "coordinates": [-43.19807052612305, -22.95790672302246] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -22.90898323059082, "long_x": -43.11017990112305 },
        "geometry": { "type": "Point", "coordinates": [-43.11017990112305, -22.90898323059082] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -22.643938064575195, "long_x": -43.25986862182617 },
        "geometry": { "type": "Point", "coordinates": [-43.25986862182617, -22.643938064575195] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -22.85628318786621, "long_x": -43.2396125793457 },
        "geometry": { "type": "Point", "coordinates": [-43.2396125793457, -22.85628318786621] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -23.507909774780273, "long_x": -46.890506744384766 },
        "geometry": { "type": "Point", "coordinates": [-46.890506744384766, -23.507909774780273] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -22.807703018188477, "long_x": -43.024349212646484 },
        "geometry": { "type": "Point", "coordinates": [-43.024349212646484, -22.807703018188477] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -22.782812118530273, "long_x": -43.295230865478516 },
        "geometry": { "type": "Point", "coordinates": [-43.295230865478516, -22.782812118530273] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -16.703767776489258, "long_x": -49.30234909057617 },
        "geometry": { "type": "Point", "coordinates": [-49.30234909057617, -16.703767776489258] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -17.743005752563477, "long_x": -48.59132766723633 },
        "geometry": { "type": "Point", "coordinates": [-48.59132766723633, -17.743005752563477] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -16.62034034729004, "long_x": -49.2378044128418 },
        "geometry": { "type": "Point", "coordinates": [-49.2378044128418, -16.62034034729004] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -16.6622257232666, "long_x": -49.226131439208984 },
        "geometry": { "type": "Point", "coordinates": [-49.226131439208984, -16.6622257232666] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -22.77817726135254, "long_x": -43.378658294677734 },
        "geometry": { "type": "Point", "coordinates": [-43.378658294677734, -22.77817726135254] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -23.538637161254883, "long_x": -46.828365325927734 },
        "geometry": { "type": "Point", "coordinates": [-46.828365325927734, -23.538637161254883] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -22.931814193725586, "long_x": -43.06760787963867 },
        "geometry": { "type": "Point", "coordinates": [-43.06760787963867, -22.931814193725586] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -22.9270076751709, "long_x": -43.17850112915039 },
        "geometry": { "type": "Point", "coordinates": [-43.17850112915039, -22.9270076751709] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -23.545331954956055, "long_x": -46.832828521728516 },
        "geometry": { "type": "Point", "coordinates": [-46.832828521728516, -23.545331954956055] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -22.78813362121582, "long_x": -42.94126510620117 },
        "geometry": { "type": "Point", "coordinates": [-42.94126510620117, -22.78813362121582] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -22.819547653198242, "long_x": -43.06245803833008 },
        "geometry": { "type": "Point", "coordinates": [-43.06245803833008, -22.819547653198242] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -16.215219497680664, "long_x": -49.238834381103516 },
        "geometry": { "type": "Point", "coordinates": [-49.238834381103516, -16.215219497680664] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -22.888898849487305, "long_x": -43.28390121459961 },
        "geometry": { "type": "Point", "coordinates": [-43.28390121459961, -22.888898849487305] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -18.009767532348633, "long_x": -49.33359146118164 },
        "geometry": { "type": "Point", "coordinates": [-49.33359146118164, -18.009767532348633] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -15.742807388305664, "long_x": -48.40730667114258 },
        "geometry": { "type": "Point", "coordinates": [-48.40730667114258, -15.742807388305664] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -22.905893325805664, "long_x": -43.10262680053711 },
        "geometry": { "type": "Point", "coordinates": [-43.10262680053711, -22.905893325805664] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -22.96889305114746, "long_x": -43.395137786865234 },
        "geometry": { "type": "Point", "coordinates": [-43.395137786865234, -22.96889305114746] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -22.975244522094727, "long_x": -43.1898307800293 },
        "geometry": { "type": "Point", "coordinates": [-43.1898307800293, -22.975244522094727] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -22.693891525268555, "long_x": -43.43393325805664 },
        "geometry": { "type": "Point", "coordinates": [-43.43393325805664, -22.693891525268555] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -16.662912368774414, "long_x": -49.2326545715332 },
        "geometry": { "type": "Point", "coordinates": [-49.2326545715332, -16.662912368774414] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -23.566617965698242, "long_x": -46.6505241394043 },
        "geometry": { "type": "Point", "coordinates": [-46.6505241394043, -23.566617965698242] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -21.730527877807617, "long_x": -41.32078170776367 },
        "geometry": { "type": "Point", "coordinates": [-41.32078170776367, -21.730527877807617] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -22.864179611206055, "long_x": -43.274288177490234 },
        "geometry": { "type": "Point", "coordinates": [-43.274288177490234, -22.864179611206055] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -17.29720115661621, "long_x": -48.27924728393555 },
        "geometry": { "type": "Point", "coordinates": [-48.27924728393555, -17.29720115661621] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -22.896108627319336, "long_x": -43.112239837646484 },
        "geometry": { "type": "Point", "coordinates": [-43.112239837646484, -22.896108627319336] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -17.772531509399414, "long_x": -50.90738296508789 },
        "geometry": { "type": "Point", "coordinates": [-50.90738296508789, -17.772531509399414] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -16.6787052154541, "long_x": -49.480533599853516 },
        "geometry": { "type": "Point", "coordinates": [-49.480533599853516, -16.6787052154541] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -23.553056716918945, "long_x": -46.8486213684082 },
        "geometry": { "type": "Point", "coordinates": [-46.8486213684082, -23.553056716918945] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -15.901765823364258, "long_x": -52.23672866821289 },
        "geometry": { "type": "Point", "coordinates": [-52.23672866821289, -15.901765823364258] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -22.892160415649414, "long_x": -43.29935073852539 },
        "geometry": { "type": "Point", "coordinates": [-43.29935073852539, -22.892160415649414] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -16.793718338012695, "long_x": -49.322261810302734 },
        "geometry": { "type": "Point", "coordinates": [-49.322261810302734, -16.793718338012695] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -22.760839462280273, "long_x": -43.447322845458984 },
        "geometry": { "type": "Point", "coordinates": [-43.447322845458984, -22.760839462280273] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -21.63628578186035, "long_x": -41.04990005493164 },
        "geometry": { "type": "Point", "coordinates": [-41.04990005493164, -21.63628578186035] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -22.88752555847168, "long_x": -43.227596282958984 },
        "geometry": { "type": "Point", "coordinates": [-43.227596282958984, -22.88752555847168] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -22.75792121887207, "long_x": -43.49161148071289 },
        "geometry": { "type": "Point", "coordinates": [-43.49161148071289, -22.75792121887207] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -22.917051315307617, "long_x": -42.9814338684082 },
        "geometry": { "type": "Point", "coordinates": [-42.9814338684082, -22.917051315307617] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -22.983484268188477, "long_x": -43.366641998291016 },
        "geometry": { "type": "Point", "coordinates": [-43.366641998291016, -22.983484268188477] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -17.02906608581543, "long_x": -50.125980377197266 },
        "geometry": { "type": "Point", "coordinates": [-50.125980377197266, -17.02906608581543] }
      }, {
        "type": "Feature",
        "properties": { "lat_y": -22.83087730407715, "long_x": -43.27669143676758 },
        "geometry": { "type": "Point", "coordinates": [-43.27669143676758, -22.83087730407715] }
      }]
    }
  };
};

// initial state
const state = getDefaultState();

// expose state
export default state;