import { some } from "lodash";
import i18n from "@/plugins/i18n";

const MenuOption = ({
  icon,
  avatarColor,
  title,
  description,
  module,
  visibleToZooxUsersOnly,
  disabled,
  route,
  options,
  context,
} = {}) => {
  return {
    icon,
    avatarColor,
    title,
    description,
    module,
    visibleToZooxUsersOnly,
    disabled,
    route,
    options: options || [],
    context: context,

    hasRoute(route) {
      return some(this.options, { route });
    },
  };
};

const Option = ({ title, route, permission, visibleToZooxUsersOnly, disabled, context }) => {
  return { title, route, permission, visibleToZooxUsersOnly, disabled, context };
};

export const getMenuOptions = () => [
  MenuOption({
    icon: "$zoox_person",
    title: i18n.t('GLOBAL.MENU_OPTIONS.CUSTOMER_360'),
    description: i18n.t('GLOBAL.PEOPLE_ANALYTICS'),
    module: "people",
    avatarColor: "#007DC6",
    visibleToZooxUsersOnly: false,
    options: [
      Option({
        title: i18n.t('GLOBAL.MENU_OPTIONS.CUSTOMER_FORM'),
        route: "/customer-file",
        permission: "find_clients"
      })
    ],
  }),
];