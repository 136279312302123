const path = require("path");

const logPath = path.join(__dirname, "../../logs/dev.log");

module.exports = {
  hostName: process.env.HOST_NAME || "http://localhost:4200",
  web: {
    port: process.env.PORT,
  },
  api: {
    auth: process.env.API_AUTH,
  },
  logging: {
    appenders: {
      console: { type: "console" },
      file: { type: "file", filename: logPath },
    },
    categories: {
      default: { appenders: ["console"], level: "debug" },
    },
  },
  file_storage: {
    publicBucket: process.env.PUBLIC_FILES_BUCKET,
    privateBucket: process.env.PRIVATE_FILES_BUCKET,
    s3_access_key: process.env.S3_ACCESS_KEY,
    s3_secret_key: process.env.S3_SECRET_KEY,
    s3_region: process.env.S3_REGION,
  },
  aws_credentials: {
    access_key: process.env.AWS_ACCESS_KEY_ID,
    secret_key: process.env.AWS_SECRET_ACCESS_KEY,
    sessionToken: process.env.AWS_SESSION_TOKEN,
  },
  db: {
    host: process.env.POSTGRES_HOST,
    user: process.env.POSTGRES_USER,
    password: process.env.POSTGRES_PASSWORD,
    database: process.env.POSTGRES_DB,
    port: process.env.POSTGRES_PORT,
  },
  redis: {
    host: process.env.REDIS_HOST,
    port: process.env.REDIS_PORT,
    user: process.env.REDIS_USER,
    password: process.env.REDIS_PASSWORD,
    tls: process.env.REDIS_TLS,
  },
};

