import ApiClient from "@/services/ApiClient";
import Vue from "vue";

const aws = require("aws-sdk");
const config = require("../../config");
const s3Settings = {};

if (config.env === "development") {
  s3Settings.accessKeyId = config.aws_credentials.access_key;
  s3Settings.secretAccessKey = config.aws_credentials.secret_key;
  s3Settings.sessionToken = config.aws_credentials.sessionToken;
}

export default class S3Service {
  constructor() {
    this.s3Obj = new aws.S3({
      ...s3Settings,
      apiVersion: "2006-03-01",
      signatureVersion: "v4",
      region: "us-east-1",
    });
    this.apiClient = new ApiClient();
  }

  async singleUpload(file, folder) {
    const key = folder + "/" + Date.now() + "-" + file.name.replace(/\s/g, "-");

    const params = {
      Bucket: "data-to-act",
      Key: key,
      Expires: 3600,
      ContentType: file.type,
    };

    let url = await this.getSignedUrl("putObject", params);

    if (url === "https://s3.amazonaws.com/") {
      url = await this.getSignedUrl("putObject", params);
    }

    return await Vue.http({
      method: "PUT",
      url: url,
      body: file,
      headers: {
        "Content-Type": "text/csv",
        Accept: "text/csv",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods":
          "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
      },
    })
      .then((resp) => {
        if (Vue.$globalEvent) {
          Vue.$globalEvent.$emit("httpRequestEnd", resp.body || resp);
        }

        return key;
      })
      .catch((err) => {
        if (Vue.$globalEvent) {
          Vue.$globalEvent.$emit("httpRequestEnd", err);
        }

        throw "erro ao subir csv";
      });

    // return await this.apiClient
    //   .put(url,
    //     blobb,
    //     {
    //       headers: {
    //         'Content-Type': file.type,
    //       }
    //     }
    //   ).then(() => {
    //     return key;
    //   })
    //   .catch(err => {
    //     console.log(err)
    //   })
  }

  async getSignedUrl(method, params) {
    const url = await this.apiClient
      .post("s3/getSignedUrl", {
        method: method,
        params: params,
      })
      .then((result) => {
        return result;
      })
      .catch((err) => {
        console.log(err);
      });

    return url;
  }

  async getSignedUrlOut(method, type, name) {
    const key = "CSVs" + "/" + Date.now() + "-" + name.replace(/\s/g, "-");

    const params = {
      Bucket: "data-to-act",
      Key: key,
      Expires: 3600,
      ContentType: type,
    };

    const url = await this.apiClient
      .post("s3/getSignedUrl", {
        method: method,
        params: params,
      })
      .then((result) => {
        return result;
      })
      .catch((err) => {
        console.log(err);
      });

    return { url, key };
  }

  deleteObjectByKey(key) {
    const params = {
      Bucket: process.env.VUE_APP_AWS_BUCKET,
      Key: key,
    };
    const data = this.s3Obj.deleteObject(params).promise();

    return data;
  }
}
