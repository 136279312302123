<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
  data: () => {
    return {
      zooxEyeToken: "",
      isZooxEye: null,
    };
  },

  async created() {

  },
  mounted() {},
  methods: {},
};
</script>
